<template>
<div class="content-wrapper">
  <div class="pt-0 content" v-if="isList">
    <div class="card mt-3">
      <div class="card-header bg-white">
        <div class="row align-items-center">
          <div class="col-md-8">
            <div class="form-row align-items-center">
              <div class="col-md-2">
                <h5 class="card-title font-weight-semibold">{{ pageTitle }}</h5>

              </div>
              <div class="col-md-6">
                <div class="form-group mb-0">
                  <div class="input-group">
                    <date-range-picker ref="picker" :locale-data="datePickerConfig.locale" :autoApply="datePickerConfig.autoApply" v-model="dateRange" :opens="'right'" :ranges="datePickerConfig.ranges" @update="updateValues">
                      <template v-slot:input="picker">
                        {{ picker.startDate | date }} - {{ picker.endDate | date }}
                      </template>
                    </date-range-picker>
                    <div class="input-group-append calendar-group">
                      <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md">
                <a href="javascript:;" @click="downloadDiit()" class="btn btn-success btn-labeled btn-labeled-left">
                  <b><i class="icon-download"></i></b>
                  <span>Download</span>
                </a>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="card-header">
        <div class="row">
          <div class="col-lg-9">
            <div class="row gx-1">
              <div class="col-md">
                <div class="row gx-1">
                  <div class="col-md-auto">
                    <div class="form-group mb-0">
                      <button @click="doRefreshData" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover.right><i class="icon-spinner11"></i></button>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <b-form-group class="mb-0">
                      <v-select placeholder=" -- Pilih DPJP -- " @input="doFill" v-model="filter.dpjp" :options="mDPJP" label="text" :reduce="v=>v.value"></v-select>
                    </b-form-group>
                  </div>

                  <div class="col-md-3">
                    <b-form-group class="mb-0">
                      <v-select placeholder=" -- Pilih Ruangan -- " @input="doFill" v-model="filter.ranap_bangsal" :options="mRanapBangsal" label="text" :reduce="v=>v.value"></v-select>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
              <input class="form-control" v-model="filter.search" @input="doSearch" placeholder="Ketik Nama/No. RM Pasien" />
              <div class="form-control-feedback">
                <i class="icon-search4 text-indigo"></i>
              </div>

              <b-button class="ml-1 d-inline-flex align-items-center" variant="outline-success" id="resetBtn" @click="doResetData()">Reset</b-button>
            </div>
          </div>
        </div>
      </div>

      <div class="table-responsive">
        <table class="table table-bordered table-striped table-hover table-sm patient-table text-uppercase table-sm">
          <thead>
            <tr>
              <th>No. Kedatangan</th>
              <th>Nama/No KTP</th>
              <th>No RM</th>
              <th>Ruangan</th>
              <th>Catatan</th>
              <th>Diit Pagi</th>
              <th>Diit Siang</th>
              <th>Diit Sore</th>
              <th width="120">Aksi</th>
            </tr>
          </thead>

          <tbody v-if="(dataList||[]).length">
            <template v-for="(v,k) in (dataList||[])">
              <tr :key="k">
                <td><span class="font-weight-semibold">{{ v.aranr_reg_code }}</span><br><span class="text-primary">PASIEN BARU</span>
                </td>
                <td>
                  <div class="d-flex flex-column align-items-start">
                    <a href="javascript:;" @click="toMonitoring(v.aranr_id)" class="font-weight-semibold border-bottom" v-b-tooltip.hover.right title="Lihat Informasi Perawatan Pasien">{{ v.ap_fullname || "-" }}</a>
                    <small>{{ v.ap_code || "-" }}</small>
                  </div>
                  <small class="text-secondary">Terdaftar {{v.aranr_reg_date | moment("DD MMMM YYYY, HH:mm")}}</small>

                  <div class="mt-2" v-if="v.aranpm_is_stop">
                    <b-badge show variant="danger">Berhenti</b-badge>

                    <p class="mb-0">
                      <small>Diberhentikan Oleh: </small> <strong>{{ v.aranpm_staff_stop }}</strong>
                    </p>
                  </div>
                </td>

                <td>
                  <div class="font-weight-semibold">{{ v.ap_code || "-" }}</div>
                </td>
                <td>
                  <div class="font-weight-semibold">{{v.mrank_name||"-"}}</div>
                  <small><span>{{v.mranb_name||"-"}}</span> - <span>{{v.mrankel_name||"-"}}</span></small>
                </td>
                <td>
                  {{ v.aranpm_note || '-' }}
                </td>
                <td>
                  {{ v.aranpm_diit_pagi || '-' }}

                  <span v-if="v.aranpm_diit_pagi">
                    <ol class="pl-2 mt-2">
                      <li v-for="(dtJenis,kJenis) in v.aranpm_jenis_diit" :key="`jenispagi-${kJenis}`">{{ dtJenis }}</li>
                    </ol>
                  </span>

                  <br />
                  <b-badge variant="danger" v-b-tooltip.hover :title="v.aranpm_puasa_note" v-if="(v.aranpm_puasa_sessions||[]).indexOf('PAGI') >= 0 && v.aranpm_puasa_date == date2String(new Date(v.aranpm_created_date))">Puasa</b-badge>
                </td>
                <td>
                  {{ v.aranpm_diit_siang || '-' }}

                  <span v-if="v.aranpm_diit_siang">
                    <ol class="pl-2 mt-2">
                      <li v-for="(dtJenis,kJenis) in v.aranpm_jenis_diit" :key="`jenissia-${kJenis}`">{{ dtJenis }}</li>
                    </ol>
                  </span>

                  <br />
                  <b-badge variant="danger" v-b-tooltip.hover :title="v.aranpm_puasa_note" v-if="(v.aranpm_puasa_sessions||[]).indexOf('SIANG') >= 0 && v.aranpm_puasa_date == date2String(new Date(v.aranpm_created_date))">Puasa</b-badge>
                </td>

                <td>
                  {{ v.aranpm_diit_sore || '-' }}

                  <span v-if="v.aranpm_diit_sore">
                    <ol class="pl-2 mt-2">
                      <li v-for="(dtJenis,kJenis) in v.aranpm_jenis_diit" :key="`jenissor-${kJenis}`">{{ dtJenis }}</li>
                    </ol>
                  </span>

                  <br />
                  <b-badge variant="danger" v-b-tooltip.hover :title="v.aranpm_puasa_note" v-if="(v.aranpm_puasa_sessions||[]).indexOf('SORE') >= 0 && v.aranpm_puasa_date == date2String(new Date(v.aranpm_created_date))">Puasa</b-badge>
                </td>
                <td>
                  <a href="javascript:;" v-b-tooltip.hover.right title="Lihat Detail" class="btn btn-icon rounded-round btn-sm alpha-info border-info" @click="openDetail(v)" v-if="moduleRole('view')">
                    <i class="icon-file-eye"></i>
                  </a>

                  <a href="javascript:;" @click="getForm(v.aranpm_id)" class="btn btn-icon rounded-round btn-sm alpha-danger border-danger text-danger-800" v-b-tooltip.hover.right title="Ubah Perencanaan" v-if="moduleRole('edit') && v.showButtonStopDiit">
                    <i class="fa-solid icon-pencil4"></i>
                  </a>

                  <a href="javascript:;" @click="openStop(v)" class="btn btn-icon rounded-round btn-sm alpha-danger border-danger text-danger-800" v-b-tooltip.hover.right title="Hentikan Pemberian Makan" v-if="moduleRole('hentikan_diit') && v.showButtonStopDiit">
                    <i class="icon-stop"></i>
                  </a>
                  
                  <a href="javascript:;" @click="cetakETiket(v)" class="btn btn-icon rounded-round btn-sm alpha-info border-info text-info-800" v-b-tooltip.hover.right title="Cetak E-Tiket">
                    <i class="icon-printer"></i>
                  </a>

                </td>
              </tr>
            </template>

          </tbody>
          <tbody v-if="data.data.length == 0">

            <tr>
              <td colspan="99">
                <div class="text-center">
                  <h4 align="center"><span v-if="filter.status || filter.search || filter.startDate || filter.endDate">Hasil pencarian tidak ditemukan</span>
                    <span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-if="!data.data">
            <tr>
              <td colspan="99">
                <div class="skeletal-comp"></div>
              </td>
            </tr>
            <tr>
              <td colspan="99">
                <div class="skeletal-comp"></div>
              </td>
            </tr>
            <tr>
              <td colspan="99">
                <div class="skeletal-comp"></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>data.per_page">
        <b-pagination class="mb-0" v-model="pageNo" :per-page="data.per_page" :total-rows="data.total" />
      </b-card-footer>

    </div>

    <b-modal id="detailData" size="md" hide-footer title="Detail Diit">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-6">
              <span class="font-weight-bold">Bentuk Makanan</span>
              <p>{{ dataModal.bentuk_makanan }}</p>
            </div>

            <div class="col-md-6">
              <span class="font-weight-bold">Jenis Diit</span>
              <ol class="pl-2">
                <li v-for="(v,k) in dataModal.jenis_diit" :key="`jenis-${k}`">{{ v }}</li>
              </ol>
            </div>

            <div class="col-md-6">
              <span class="font-weight-bold">Nama Petugas</span>
              <p>{{ dataModal.nama_petugas }}</p>
            </div>

            <div class="col-md-6">
              <span class="font-weight-bold">Catatan</span>
              <p>{{ dataModal.note||'-' }}</p>
            </div>

            <div class="col-md-12 mt-3">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Pagi ({{ configTime.pagi }})</th>
                      <th>Siang ({{ configTime.siang }})</th>
                      <th>Sore ({{ configTime.sore }})</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ dataModal.diit_pagi || '-' }}</td>
                      <td>{{ dataModal.diit_siang || '-' }}</td>
                      <td>{{ dataModal.diit_sore || '-' }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal id="formData" size="md" hide-footer title="Edit Diit">
      <validation-observer ref="VFormDiit">
        <b-form @submit.prevent="doSubmit()">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-6">
                  <label>Bentuk Makanan <span class="text-danger">*</span></label>
                  <v-select :options="mrBentukMakanan" label="label" v-model="row.bentuk_makanan" />

                  <VValidate name="Bentuk Makanan" v-model="row.bentuk_makanan" :rules="mrValidation.bentuk_makanan" />
                </div>

                <div class="col-md-6">
                  <label>Jenis Diit <span class="text-danger">*</span></label>
                  <v-select :options="mrJenisDiit" label="label" v-model="row.jenis_diit" class="vs__multiple" multiple />

                  <VValidate name="Jenis Diit" v-model="row.jenis_diit" :rules="mrValidation.jenis_diit" />
                </div>

                <div class="col-md-12">
                  <div class="row mt-3">

                    <div class="col-md-3 align-items-center">
                      <b-form-checkbox v-model="row.is_puasa" value="Y" unchecked-value="N">
                        Pasien Puasa?
                      </b-form-checkbox>
                    </div>

                    <div class="col-md-4" v-if="row.is_puasa == 'Y'">
                      <label>Waktu Puasa</label>
                      <v-select :options="mrPuasaSessions" :reduce="v => v.value" label="label" v-model="row.puasa_sessions" class="vs__multiple" multiple />

                      <VValidate name="Puasa" v-model="row.puasa_sessions" :rules="{ required: 1 }" />
                    </div>

                    <div class="col-md-5" v-if="row.is_puasa == 'Y'">
                      <label>Catatan Puasa</label>
                      <b-form-textarea v-model="row.puasa_note" placeholder="Catatan Puasa" :formatter="normalText">

                      </b-form-textarea>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 mt-3">
                  <label>Catatan</label>
                  <b-form-textarea v-model="row.note" placeholder="Catatan" :formatter="normalText">

                  </b-form-textarea>
                </div>

                <div class="col-md-12 mt-3">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Pagi ({{ configTime.pagi }})</th>
                        <th>Siang ({{ configTime.siang }})</th>
                        <th>Sore ({{ configTime.sore }})</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <v-select :options="mrBentukMakanan" class="mt-2" label="label" v-model="row.diit_pagi" v-if="row.bentuk_makanan" />
                        </td>
                        <td>
                          <v-select :options="mrBentukMakanan" class="mt-2" label="label" v-model="row.diit_siang" v-if="row.bentuk_makanan" />
                        </td>
                        <td>
                          <v-select :options="mrBentukMakanan" class="mt-2" label="label" v-model="row.diit_sore" v-if="row.bentuk_makanan" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="col-md-12">
                  <div class="text-right">
                    <button class="btn" type="button" @click="$bvModal.hide('formData')">Batal</button>
                    <button type="submit" class="btn btn-labeled btn-labeled-left btn-success">
                      <b><i class="icon-checkmark"></i></b>
                      <span>Simpan</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>

    <b-modal id="stopDiit" size="md" hide-footer title="Hentikan Diit">
      <div class="row">
        <div class="col-md-6" :class="{'col-md-6': date2String(row.date) != date2String(new Date()), 'col-md-12': date2String(row.date) == date2String(new Date()) && (mrWaktuDiit||[]).length < 1}">
          <div class="form-group">
            <label>Waktu Berhenti</label>
            <div class="input-group">
              <datepicker input-class="form-control transparent" placeholder="Pilih Tanggal Puasa" class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="row.date" :disabled-dates="disabledDateStopDiit">
              </datepicker>
              <div class="input-group-append calendar-group">
                <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6" v-if="
              (date2String(row.date) != date2String(new Date())) || (date2String(row.date) == date2String(new Date()) && (mrWaktuDiit||[]).length > 0)
            ">
          <div class="form-group">
            <label class="text-white">Waktu</label>
            <v-select :options="date2String(row.date) != date2String(new Date()) ? mrPuasaSessions : mrWaktuDiit" :reduce="v => v.value" label="label" v-model="row.sessions" class="vs__multiple" multiple placeholder="-- Waktu Diit --" />
          </div>
        </div>

        <div class="col-md-12">
          <b-alert show variant="danger">
            Dengan menyimpan data ini, Anda yakin bahwa perawatan Pasien sudah selesai dan pemberian makan/diit Pasien dihentikan mulai dari waktu diit yang Anda pilih di atas.
          </b-alert>
        </div>

        <div class="col-md-12">
          <div class="text-right">
            <button type="submit" class="btn btn-labeled btn-labeled-left btn-success" @click="stopDiit()">
              <b><i class="icon-checkmark"></i></b>
              <span>Simpan</span>
            </button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import Datepicker from 'vuejs-datepicker'

import Gen from '@/libs/Gen.js'

const _ = global._
const moment = require('moment')

import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  extends: GlobalVue,
  components: {
    DateRangePicker, Datepicker
  },
  data() {
    return {
      patientData: {},
      loading: {
        patientInfo: false,
      },

      dataModal: {},
      row: {},

      mrPendidikanKesehatan: [],
      mDPJP: [],
      mRanapBangsal: [],
      mrJenisDiit: [],
      mrBentukMakanan: [],
      mrPuasaSessions: [],
      mrWaktuDiit: [],
      mrValidation: {},
      configTime: {},

      dateRange: {
        startDate: new Date(),
        endDate: new Date(),
      },

      datePickerConfig: {
        startDate: new Date(),
        endDate: new Date(),
        autoApply: true,
        ranges: {
          'Hari Ini': [new Date(), new Date()],
          '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
          '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
        },
        applyClass: 'btn-sm btn-primary',
        cancelClass: 'btn-sm btn-light',
        locale: {
          applyLabel: 'Terapkan',
          cancelLabel: 'Batal',
          direction: 'ltr',
          format: 'mm/dd/yyyy',
          separator: ' - ',
        }
      },

      disabledDateStopDiit: {
        to: new Date(),
      }
    }
  },

  mounted() {
    if (this.isList) {
      this.doConvertDate()

      this.apiGet()
    } else {
      this.getPatientInfo()
      this.getForm()
    }
  },

  methods: {
    addDaysToDate(diff){
      const date = new Date()
      date.setDate(date.getDate() + diff)

      return date
    },
    decreaseDaysToDate(diff){
      const date = new Date()
      date.setDate(date.getDate() - diff)

      return date
    },
    stopDiit() {
      this.$swal.fire({
        title: 'Apakah Anda Yakin Untuk Memberhentikan Diit?',
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hentikan",
        showConfirmButton: true,
        showCancelButton: true,

        icon: 'warning',

      }).then(button => {
        if (button.isConfirmed) {
          this.$swal.fire({
            title: 'Loading...',
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
              this.$swal.showLoading()
            }
          })

          Gen.apiRest(
            "/do/" + this.modulePage, {
              data: {
                ...this.row,
                type: 'hentikan-pemberian-makan',
                id_registrasi: this.dataModal.aranr_id
              }
            },
            "POST"
          ).then(resp => {
            this.$swal.close()
            this.$swal({
              title: resp.data.message,
              icon: 'success',
              confirmButtonText: 'Ok'
            }).then(result => {
              if (result.value) {
                this.apiGet()
                this.$bvModal.hide("stopDiit")
              }
            })
          }).catch(() => {
            this.$swal.close()

            return this.$swal({
              icon: 'error',
              text: 'Terjadi Kesalahan Yang Tidak Diketahui'
            })
          })

        }
      })
    },
    openStop(data) {
      this.$set(this, 'dataModal', {
        ...data
      })

      this.$bvModal.show('stopDiit')
      this.getForm(null, false)
    },
    date2String(date) {
      if (!date) return ``
      return `${date.getFullYear()}-${(date.getMonth()+1).toString().padStart(2, '0')}-${date.getDate()}`
    },
    downloadDiit() {
      this.$swal.fire({
        title: 'Processing...',
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading()
        }
      })

      Gen.apiRest(
          "/do/" + this.modulePage, {
            data: {
              type: "download-diit",
              id_registrasi: this.$route.params.pageSlug,
              ...this.filter
            }
          },
          "POST"
        )
        .then(res => {
          const linkSource = res.data
          const downloadLink = document.createElement("a")
          downloadLink.href = linkSource
          downloadLink.download = `Diit-${new Date().getTime()}.pdf`
          downloadLink.click()

          this.$swal.close()
        })
        .catch(() => {
          this.$swal.close()
          // this.globalVar.apiGenError( err.response.data )
        })
    },

    doResetData() {
      this.doReset()
      this.dateRange = {
        startDate: new Date(),
        endDate: new Date(),
      }
      this.doConvertDate()
    },
    updateValues(e) {
      this.doConvertDate()
      this.doFill()
    },
    doRefreshData() {
      this.apiGet()
      window.scrollTo(0, 0)
    },
    doConvertDate() {
      this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
      this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
    },
    doPageOne() {
      this.filter.page = 1
    },
    doFill() {
      this.doPageOne()
      this.doFilter()
    },
    doSearch: _.debounce(function () {
      this.doPageOne()
      this.doFilter()
    }, 100),

    openDetail(data) {
      this.$bvModal.show('detailData')

      Gen.apiRest(
        "/get/" + this.modulePage, {
          params: {
            type: "get-detail",
            id_pemberian_makan: data.aranpm_id
          }
        },
      ).then(res => {
        this.$set(this, 'dataModal', {
          ...data,
          ...res.data.data
        })

        this.$set(this, 'configTime', res.data.configTime)
      })
    },

    getForm(id, showFormData = true) {
      if (showFormData) this.$bvModal.show('formData')

      Gen.apiRest(
        "/get/" + this.modulePage, {
          params: {
            type: "get-form",
            id_pemberian_makan: id
          }
        },
      ).then(res => {
        _.forEach(res.data, (v, k) => {
          this.$set(this, k, v)
        })
        
        this.$set(this, 'disabledDateStopDiit', {
          from: new Date(Date.parse(res.data.disabledDateStop.from)),
          to: new Date(Date.parse(res.data.disabledDateStop.to)),
        })
      })
    },

    getPatientInfo() {
      this.$set(this.loading, 'patientInfo', true)
      Gen.apiRest(
        "/do/" + 'RanapPasienPerawatan', {
          data: {
            type: "get-data-pasien",
            id_registrasi: this.$route.query.idRegistrasi
          }
        },
        "POST"
      ).then(resp => {
        this.$set(this, 'patientData', resp.data.data)

        this.$set(this.loading, 'patientInfo', false)
      })
    },

    doSubmit() {
      this.$swal.fire({
        title: 'Loading...',
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading()
        }
      })

      Gen.apiRest(
        "/do/" + this.modulePage, {
          data: {
            ...this.row,
            type: 'update-pemberian-makan'
          }
        },
        "POST"
      ).then(resp => {
        this.$swal.close()
        this.$swal({
          title: resp.data.message,
          icon: 'success',
          confirmButtonText: 'Ok'
        }).then(result => {
          if (result.value) {
            this.apiGet()
            this.$bvModal.hide("formData")

          }
        })
      }).catch(() => {
        this.$swal.close()

        return this.$swal({
          icon: 'error',
          text: 'Terjadi Kesalahan Yang Tidak Diketahui'
        })
      })
    },
    
    cetakETiket(row){
      let dob = moment(row.ap_dob).format("DD-MM-YYYY")
      let tglDiit = moment(row.aranpm_created_date).format("DD-MM-YYYY")
      let jenisDiit = (row.aranpm_jenis_diit||[]).join(",")
      let ruangan = row.mrank_name + ' - ' + row.mranb_name + ' - ' + row.mrankel_name
      let newWindow = window.open('', '', 'left=0,top=0,width=189,height=57,toolbar=0,scrollbars=0,status=0,addressbar=0'),
      document = newWindow.document.open(),
      pageContent =
        '<!DOCTYPE html>' +
        '<html>' +
        '<head>' +
        '<meta charset="utf-8" />' +
        '<title>Inventory</title>' +
        '<style type="text/css">body {-webkit-print-color-adjust: exact; font-family: Arial; }</style>' +
        '</head>' +
        '<body>' +
        `<div style="position: relative;max-width: 340px;min-height: 856px;">
          <div
            style="height:100%;position: relative;font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;color: #333;line-height: 1.5;font-size: 13px;">
            <div style="border-bottom: .5px dashed #333;position:relative;">
              <table border="1" style="border-collapse: collapse;width: 100%;">
                <tr>
                  <td>
                    <h6 style="margin: auto;text-align: center;font-size: 16px;">ETIKET DIIT</h6>
                  </td>
                  <td>
                    <div style="display: flex;margin-left: 5px;">
                    <img src="${this.assetLocal('global_assets/images/logo-rsh-mark.png')}"
                      style="width: 20%;height: 55px;margin-right: 10px;margin-top: 2px;"
                      alt="">
                    <h6 style="font-size: 15px;margin: 9px 1px;/* line-height: 13px; */">Rumah Sakit Harapan Magelang</h6>
                    </div>
                  </td>
                </tr>
              </table>
              <table
                style="border-bottom: 1px solid #333;border-right: 1px solid #333;border-left: 1px solid #333;width: 100%;">
                <tr>
                  <td width="40%">Nama</td>
                  <td>:</td>
                  <td>${row.ap_fullname}</td>
                </tr>
                <tr>
                  <td width="40%">No. RM</td>
                  <td>:</td>
                  <td>${row.ap_code}</td>
                </tr>
                <tr>
                  <td width="40%">Tgl Lahir</td>
                  <td>:</td>
                  <td>${dob}</td>
                </tr>
                <tr>
                  <td width="40%">NIK</td>
                  <td>:</td>
                  <td>${row.ap_nik}</td>
                </tr>
              </table>
              <table style="width: 100%; border-right: 1px solid #333;border-left: 1px solid #333;">
                <tr>
                  <td width="40%">Ruangan</td>
                  <td>:</td>
                  <td>${ruangan}</td>
                </tr>
                <tr>
                  <td width="40%">Tgl / Jenis DIIT</td>
                  <td>:</td>
                  <td>${tglDiit} / ${jenisDiit}</td>
                </tr>
                <tr>
                  <td width="40%">Jadwal Makan</td>
                  <td>:</td>
                  <td>Pagi</td>
                </tr>
                <tr>
                  <td width="40%">Batas Konsumsi</td>
                  <td>:</td>
                  <td></td>
                </tr>
                <tr>
                  <td colspan="3">
                    <span style="display: block;">
                      *Alat Makan Diambil sesudah 1 jam Pemberian
                    </span>
                  </td>
                </tr>
              </table>
            </div>
            <div style="border-bottom: .5px dashed #333;;position:relative;">
              <table border="1" style="border-collapse: collapse;width: 100%;">
                <tr>
                  <td>
                    <h6 style="margin: auto;text-align: center;font-size: 16px;">ETIKET DIIT</h6>
                  </td>
                  <td>
                    <div style="display: flex;margin-left: 5px;">
                      <img src="${this.assetLocal('global_assets/images/logo-rsh-mark.png')}"
                        style="width: 20%;height: 55px;margin-right: 10px;margin-top: 2px;" alt="">
                      <h6 style="font-size: 15px;margin: 9px 1px;/* line-height: 13px; */">Rumah Sakit Harapan Magelang</h6>
                    </div>
                  </td>
                </tr>
              </table>
              <table
                style="border-bottom: 1px solid #333;border-right: 1px solid #333;border-left: 1px solid #333;width: 100%;">
                <tr>
                  <td width="40%">Nama</td>
                  <td>:</td>
                  <td>${row.ap_fullname}</td>
                </tr>
                <tr>
                  <td width="40%">No. RM</td>
                  <td>:</td>
                  <td>${row.ap_code}</td>
                </tr>
                <tr>
                  <td width="40%">Tgl Lahir</td>
                  <td>:</td>
                  <td>${dob}</td>
                </tr>
                <tr>
                  <td width="40%">NIK</td>
                  <td>:</td>
                  <td>${row.ap_nik}</td>
                </tr>
              </table>
              <table style="width: 100%; border-right: 1px solid #333;border-left: 1px solid #333;">
                <tr>
                  <td width="40%">Ruangan</td>
                  <td>:</td>
                  <td>${ruangan}</td>
                </tr>
                <tr>
                  <td width="40%">Tgl / Jenis DIIT</td>
                  <td>:</td>
                  <td>${tglDiit} / ${jenisDiit}</td>
                </tr>
                <tr>
                  <td width="40%">Jadwal Makan</td>
                  <td>:</td>
                  <td>Siang</td>
                </tr>
                <tr>
                  <td width="40%">Batas Konsumsi</td>
                  <td>:</td>
                  <td></td>
                </tr>
                <tr>
                  <td colspan="3">
                    <span style="display: block;">
                      *Alat Makan Diambil sesudah 1 jam Pemberian
                    </span>
                  </td>
                </tr>
              </table>
            </div>
            <div style="border-bottom: .5px solid #333;;position:relative;">
              <table border="1" style="border-collapse: collapse;width: 100%;">
                <tr>
                  <td>
                    <h6 style="margin: auto;text-align: center;font-size: 16px;">ETIKET DIIT</h6>
                  </td>
                  <td>
                    <div style="display: flex;margin-left: 5px;">
                      <img src="${this.assetLocal('global_assets/images/logo-rsh-mark.png')}"
                        style="width: 20%;height: 55px;margin-right: 10px;margin-top: 2px;" alt="">
                      <h6 style="font-size: 15px;margin: 9px 1px;/* line-height: 13px; */">Rumah Sakit Harapan Magelang</h6>
                    </div>
                  </td>
                </tr>
              </table>
              <table
                style="border-bottom: 1px solid #333;border-right: 1px solid #333;border-left: 1px solid #333;width: 100%;">
                <tr>
                  <td width="40%">Nama</td>
                  <td>:</td>
                  <td>${row.ap_fullname}</td>
                </tr>
                <tr>
                  <td width="40%">No. RM</td>
                  <td>:</td>
                  <td>${row.ap_code}</td>
                </tr>
                <tr>
                  <td width="40%">Tgl Lahir</td>
                  <td>:</td>
                  <td>${dob}</td>
                </tr>
                <tr>
                  <td width="40%">NIK</td>
                  <td>:</td>
                  <td>${row.ap_nik}</td>
                </tr>
              </table>
              <table style="width: 100%; border-right: 1px solid #333;border-left: 1px solid #333;">
                <tr>
                  <td width="40%">Ruangan</td>
                  <td>:</td>
                  <td>${ruangan}</td>
                </tr>
                <tr>
                  <td width="40%">Tgl / Jenis DIIT</td>
                  <td>:</td>
                  <td>${tglDiit} / ${jenisDiit}</td>
                </tr>
                <tr>
                  <td width="40%">Jadwal Makan</td>
                  <td>:</td>
                  <td>Malam</td>
                </tr>
                <tr>
                  <td width="40%">Batas Konsumsi</td>
                  <td>:</td>
                  <td></td>
                </tr>
                <tr>
                  <td colspan="3">
                    <span style="display: block;">
                      *Alat Makan Diambil sesudah 1 jam Pemberian
                    </span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        `
        +
        '</body></html>'
      document.write(pageContent)
      document.close()
      newWindow.moveTo(0, 0)
      newWindow.resizeTo(screen.width, screen.height)
      setTimeout(function() {
          newWindow.print()
          newWindow.close()
      }, 250)
    }
  },

  computed: {
    pendidikanKesehatanGroups() {
      return _.chunk(this.dataModal.pendidikan_kesehatan, 2)
    },
  },
  filters: {
    date(val) {
      return val ? moment(val).format("D MMM YYYY") : ""
    }
  },
  watch: {
    $route() {
      if (this.isList) {
        this.doConvertDate()

        this.apiGet()
      } else {
        this.getPatientInfo()
        this.getForm()
      }
    },

    'row.date'(){
      this.$set(this.row, 'sessions', null)
    }
  },
}
</script>
